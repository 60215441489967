<template>
  <div :id="modalId" :class="classes" :style="this.styles" tabindex="-1" role="dialog">
    <div class="modal-dialog" v-if="data">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('generic-str.edit')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{$tc('generic-str.tel', 1)}}</label>
                <div class="col-md-12">
                  <textarea v-model="data.mobile_number" type="text" class="form-control"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">{{$t('generic-str.cancel')}}</button>
            <button
              :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >{{$t('generic-str.save')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
  },
  data() {
    return {
      isSending: false,
    };
  },
  methods: {
    save() {
      this.isSending = true;
      SmsService.editOptouts(this.data).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Número atualizado',
            type: 'success',
          });
          this.$root.$emit('updated.optout');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
          console.log(error.response.data.message);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
