<template>
  <div :id="modalId" :class="classes" :style="this.styles" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('generic-str.status.lbl-new')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{$tc('generic-str.lbl-number', 2)}}</label>
                <div class="col-md-12">
                  <textarea v-model="form.numbers" type="text" class="form-control" placeholder="Ex: 38999628122,61999628692"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{$t('key-word-component.label')}}</label>
                <div class="col-md-12">
                  <select-input
                    v-model="form.keyword_id"
                    :options="blacklists"
                    label="word"
                    :reduce="blacklist => blacklist.id"
                    :clearable="false"
                  ></select-input>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">{{$t('generic-str.cancel')}}</button>
            <button
              :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >{{$t('generic-str.save')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Modal from '@/mixins/Modal';
import SelectInput from '@/components/SelectInput.vue';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    SelectInput,
  },
  data() {
    return {
      blacklists: [],
      form: {},
      isSending: false,
    };
  },
  created() {
    this.fetchBlackList();
  },
  methods: {
    fetchBlackList(page = 1) {
      SmsService.getKeylist({}, page).then(
        (response) => {
          this.loaded = true;
          console.log(response);
          this.blacklists = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    save() {
      this.isSending = true;
      console.log(this.form);
      SmsService.createOptouts({
        keyword_id: this.form.keyword_id,
        numbers: this.form.numbers,
      }).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Optout criado com sucesso',
            type: 'success',
          });
          this.$root.$emit('updated.optout');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.response.data.message,
            type: 'danger',
          });
          this.isSending = false;
          console.log(error.response.data.message);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
