import { render, staticRenderFns } from "./CreateOptoutsModal.vue?vue&type=template&id=507698b1&scoped=true&"
import script from "./CreateOptoutsModal.vue?vue&type=script&lang=js&"
export * from "./CreateOptoutsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507698b1",
  null
  
)

export default component.exports